<template>
    <VideoContent v-editable="blok" :data="videoContent" />
</template>

<script setup lang="ts">
import VideoContentType from '@types/VideoContentType';
import ButtonType from '@/types/ButtonType';
import VideoContent from '~/components/page-building/VideoContent.vue';
import { useVideoStructuredData } from '~/composables/useStructuredData';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const buttons = props.blok.buttons
    ? props.blok.buttons.map((button: any) => {
          return {
              label: button.label,
              style: button.style,
              url: button.link.cached_url,
              vEditable: button,
          } as ButtonType;
      })
    : [];

const videoContent = computed<VideoContentType>(() => {
    return {
        videoUrl: props.blok.video_url,
        heading: props.blok.heading,
        content: richTextToHtml(props.blok.content) as string,
        buttons,
        reverseLayout: props.blok.reverse_layout,
        backgroundColor: props.blok.background_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        arrowCallout: props.blok.arrow_callout_type
            ? {
                  type: props.blok.arrow_callout_type,
                  text: richTextToHtml(props.blok.arrow_callout_text) as string,
              }
            : null,
        bottomAngle: props.blok.bottom_angle,
    };
});

const structuredData = useVideoStructuredData(videoContent.value);

useServerHead(() => ({
    script: structuredData,
}));
</script>
